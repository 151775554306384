import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import SuccessPayment from '../../templates/SuccessPayment';

class Page extends React.PureComponent {
  render() {
    return (
      <Layout page={'product'}>
        <SuccessPayment />
      </Layout>
    );
  }
}

export default withIntl(Page);
