import { Button } from 'mw-style-react';
import React from 'react';
import { Link } from '../../i18n';
import successIcon from '../../images/success-payment.svg';
import './SuccessPayment.scss';

const host = process.env.GATSBY_HOST;
const account = `${process.env.GATSBY_ACCOUNT}/license/list`;

export default function SuccessPayment() {
  return (
    <section className="product success-payment">
      <div className="product__header" />
      <div className="success-payment__container">
        <div>
          <img className="success-payment__icon" src={successIcon} alt="" />
        </div>
        <div className="success-payment__text">Thank you for purchase</div>
        <div className="success-payment__actions">
          <Link to={host}>
            <Button
              className="success-payment__action button__default"
              label={'Go to Corezoid'}
              size={'small'}
            />
          </Link>
          <Link to={account}>
            <Button
              className="success-payment__action button__secondary"
              label={'Go to License and billings'}
              size={'small'}
            />
          </Link>
        </div>
      </div>
    </section>
  );
}
